import * as React from 'react';

import { SVGProps } from '../../types';

export const IconDashSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 8.25a.75.75 0 01.102 1.493L12 9.75H6a.75.75 0 01-.102-1.493L6 8.25h6z" />
  </svg>
);
