import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconCheckmarkSvg } from './IconCheckmarkSvg';

export type IconCheckmarkProps = UseIconProps;

export function IconCheckmark(props: IconCheckmarkProps) {
  const iconProps = useIcon({ size: 'large', ...props });

  return <Box tagName={IconCheckmarkSvg} {...iconProps} />;
}
