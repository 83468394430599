import './styles/toast.scss';

import React from 'react';

import cx from 'classnames';

import { IconX as MicroX } from '~shared/components/icons/micro';
import { IconCheckmark } from '~shared/components/icons/standard';

import { IconExclamationTriangle } from '../icons/large';
import { toast as _toast } from './';
import { Toast } from './types';

// critical icon for critical toasts
const CriticalIcon = () => {
  return (
    <div className="status-icon mr-4">
      <IconExclamationTriangle className="text-red-600 absolute h-9 w-9" />
    </div>
  );
};

// positive icon for positive toasts
const PositiveIcon = () => {
  return (
    <div className="status-icon bg-green-500 mr-4">
      <IconCheckmark className="text-white p-1" />
    </div>
  );
};

// message can be either a function (custom dismiss button), or a simple string. Handles supported JSX content
interface SubProps {
  toast: Toast;
}
const Message = ({ toast }: SubProps) => {
  return (
    <div className="flex justify-center font-medium flex-auto text-sm leading-6">
      {typeof toast.message === 'function'
        ? toast.message(toast)
        : toast.message}
    </div>
  );
};
const Icon = ({ toast }: SubProps) => {
  if (toast.icon) {
    return (
      <div className="animated-icon mr-4">
        <span>{toast.icon}</span>
      </div>
    );
  } else if (toast.tone === 'positive') {
    return <PositiveIcon />;
  } else if (toast.tone === 'critical') {
    return <CriticalIcon />;
  } else {
    return <></>;
  }
};

const Closeable = ({ toast }: SubProps) => {
  const closeClass = cx(
    'dismiss-toast text-white ml-5',
    toast.tone === 'critical' ? 'bg-red-400 text-blue-900' : ''
  );
  return (
    <button className={closeClass} onClick={() => _toast.dismiss(toast.id)}>
      <MicroX />
    </button>
  );
};

interface ToastBarProps {
  toast: Toast;
}

export const ToastBar = ({ ...props }: ToastBarProps) => {
  const toastClass = cx(
    'max-w-lg flex items-start pointer-events-auto py-3 px-5 rounded-6 mb-2 shadow-modal',
    props.toast.tone === 'critical'
      ? 'bg-red-50 text-red-900'
      : 'bg-blue-900 text-white'
  );
  return (
    <div className={toastClass} role="alert" aria-live="polite">
      <Icon toast={props.toast} />
      <Message toast={props.toast} />
      {!props.toast.disableClose && <Closeable toast={props.toast} />}
    </div>
  );
};
