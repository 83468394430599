import * as React from 'react';

import { SVGProps } from '../../types';

export const IconEllipsisSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.5 8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
    />
  </svg>
);
