import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconXSvg } from './IconXSvg';

export type IconXProps = UseIconProps;

export function IconX(props: IconXProps) {
  const iconProps = useIcon({ size: 'micro', ...props });

  return <Box tagName={IconXSvg} {...iconProps} />;
}
