import nanoid from 'nanoid';

import { dispatcher } from '../store/store';
import { ActionType, Message, ToastOptions, Tone } from '../types';

// function that generates the toast object
const createToast = (
  message: Message,
  tone: Tone = 'normal',
  options?: ToastOptions
) => ({
  createdAt: Date.now(),
  visible: true,
  tone,
  message,
  pauseDuration: 0,
  ...options,
  duration: options?.duration || 5000,
  preset: options?.preset || null,
  icon: options?.icon || null,
  theme: options?.theme || 'light',
  id: nanoid(),
});

// create handler to deal with toast assignment
const createHandler =
  (tone: Tone) => (message: Message, options?: ToastOptions) => {
    const toast = createToast(message, tone, options);
    dispatcher({ type: ActionType.ADD_TOAST, toast });
    return toast.id;
  };

// export generic toast creator function
export const toast = (message: Message, options?: ToastOptions) =>
  createHandler('normal')(message, options);

// shorthand for various formats
toast.positive = createHandler('positive');
toast.critical = createHandler('critical');

// action handlers
toast.remove = (toastId?: string) => {
  dispatcher({ type: ActionType.REMOVE_TOAST, toastId });
};
toast.dismiss = (toastId?: string) => {
  dispatcher({ type: ActionType.DISMISS_TOAST, toastId });
};
