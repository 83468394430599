import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconPencilSvg } from './IconPencilSvg';

export type IconPencilProps = UseIconProps;

export function IconPencil(props: IconPencilProps) {
  const iconProps = useIcon({ size: 'large', ...props });

  return <Box tagName={IconPencilSvg} {...iconProps} />;
}
