import { ErrorEvent } from '@sentry/types';
enum IgnoredErrorTypes {
  UNHANDLED_REJECTION = 'UnhandledRejection',
}

enum IgnoredErrorMessages {
  RESIZE_OBSERVER_LIMIT_EXCEEDED = 'ResizeObserver loop limit exceeded', // Ignored because the error is benign (won't break the site) https://github.com/w3c/csswg-drafts/issues/5023
  RESIZE_OBSERVER_UNDELIVERED_NOTIFICATION = 'ResizeObserver loop completed with undelivered notifications.', // Safely Ignored (won't break the site) https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
}

const RateLimitedErrorMessages: {
  [key: string]: { message: string; rate: number };
} = {};

function getSentryMessage(errorEvent: ErrorEvent) {
  const { message } = errorEvent;
  return message ? message : null;
}

function getRateLimitedSentryEventInfo(sentryErrorEvent: ErrorEvent) {
  const message = getSentryMessage(sentryErrorEvent);
  switch (message) {
    default:
      return null;
  }
}

function buildRateLimitedSentryEvent(
  sentryErrorEvent: ErrorEvent,
  rateLimitedEventInfo: ReturnType<typeof getRateLimitedSentryEventInfo>
) {
  if (sentryErrorEvent && rateLimitedEventInfo) {
    const { rate, message } = rateLimitedEventInfo;
    if (Date.now() % rate === 1) {
      sentryErrorEvent.message = `Rate limited (1/${rate}): ${message}`;
      return sentryErrorEvent;
    }
  }
  return null;
}

function getErrorType(sentryErrorEvent: ErrorEvent) {
  const { exception } = sentryErrorEvent;
  if (exception?.values && exception.values.length > 0) {
    return exception.values[0]?.type ? exception.values[0]?.type : null;
  }
  return null;
}

function isIgnoredSentryError(errorEvent: ErrorEvent) {
  const errorType = getErrorType(errorEvent);
  if (errorType) {
    switch (errorType) {
      case IgnoredErrorTypes.UNHANDLED_REJECTION:
        return true;
      default:
        return false;
    }
  }
  return false;
}

function isIgnoredErrorMessage(message: string) {
  return Object.keys(IgnoredErrorMessages).some(
    (key) => IgnoredErrorMessages[key] === message
  );
}

function isRateLimitedErrorMessage(message: string) {
  return Object.keys(RateLimitedErrorMessages).some(
    (key) => RateLimitedErrorMessages[key].message === message
  );
}

export {
  isIgnoredSentryError,
  isIgnoredErrorMessage,
  buildRateLimitedSentryEvent,
  RateLimitedErrorMessages,
  getSentryMessage,
  getErrorType,
  getRateLimitedSentryEventInfo,
  isRateLimitedErrorMessage,
  IgnoredErrorMessages,
  IgnoredErrorTypes,
};
