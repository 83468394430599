import * as React from 'react';

import { SVGProps } from '../../types';

export const IconPencilSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26.028 5.284a2.11 2.11 0 011.519.644l2.493 2.499a2.112 2.112 0 01-.038 3.195L13.058 28.606l.01.01-6.472 2.129c-.458.033-.801-.066-1.03-.296-.23-.23-.335-.599-.316-1.106l2.13-6.397.003.003L24.407 6.041a2.11 2.11 0 011.62-.757zM8.648 23.882l-1.722 5.174 5.192-1.708-3.47-3.466zm12.756-12.746L9.674 22.787l3.54 3.536L24.892 14.62l-3.488-3.483zm4.624-4.352a.609.609 0 00-.396.144l-.076.077-.092.1-2.996 2.974 3.483 3.48 2.99-2.996.09-.084a.612.612 0 00.219-.47.624.624 0 00-.183-.434L26.47 6.972a.609.609 0 00-.442-.188z" />
  </svg>
);
