import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconArrowSvg } from './IconArrowSvg';

const directionClassMap = {
  up: '',
  right: 'rotate-90',
  down: 'rotate-180',
  left: '-rotate-90',
};

export type IconArrowProps = UseIconProps & {
  direction?: 'up' | 'right' | 'down' | 'left';
};

export function IconArrow({ direction = 'up', ...props }: IconArrowProps) {
  const iconProps = useIcon({ size: 'large', ...props });
  const iconClass = [
    'transform origin-center transition-transform duration-300 ease-in-out',
    directionClassMap[direction],
  ].join(' ');

  return <Box tagName={IconArrowSvg} className={iconClass} {...iconProps} />;
}
