import * as React from 'react';

import { SVGProps } from '../../types';

export const IconInformationSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.75 7a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h1.5zM9 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" />
  </svg>
);
