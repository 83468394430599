import * as React from 'react';

import { SVGProps } from '../../types';

export const IconChevronSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.356 6.02a.75.75 0 011.133.977l-.072.085-4.898 4.897a.75.75 0 01-.976.073l-.085-.073-4.875-4.875a.75.75 0 01.977-1.133l.084.072 4.344 4.344 4.368-4.366z" />
  </svg>
);
