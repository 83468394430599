/** This is a helper function to cut down on the boiler plate for dynamic imports.
 *  This function takes in a function because webpack uses magic comments to name filenames.
 */
function dynamicImport<T, V>({
  moduleImport: moduleImport,
  moduleProperty = (module) => (module as any).default,
}: {
  moduleImport: () => Promise<T>;
  moduleProperty?: (module: T) => V;
}) {
  return async () => {
    const module = await moduleImport();
    return moduleProperty(module) as V;
  };
}

export default dynamicImport;
export { dynamicImport };
