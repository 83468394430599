import * as React from 'react';

import { SVGProps } from '../../types';

export const IconStrokeSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.47 5.47a.75.75 0 011.133.976l-.073.084-6 6a.75.75 0 01-1.133-.976l.073-.084 6-6z" />
  </svg>
);
