import * as React from 'react';

import { SVGProps } from '../../types';

export const IconCheckmarkSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.475 5.464a.75.75 0 011.123.989l-.073.083-6.13 6a.75.75 0 01-.965.07l-.084-.07-3.87-3.79a.75.75 0 01.964-1.143l.085.072L7.87 10.95l5.604-5.487z" />
  </svg>
);
