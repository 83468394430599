import * as React from 'react';

import { SVGProps } from '../../types';

export const IconExclamationTriangleSvg = ({
  title,
  titleId,
  ...props
}: SVGProps) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M19.5 4.402A3 3 0 0120.598 5.5L31.99 25.232a3 3 0 01-2.598 4.5H6.608a3 3 0 01-2.598-4.5L15.402 5.5A3 3 0 0119.5 4.402zM18 21a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm.957-8l-1.955.002a.5.5 0 00-.456.45v.09l.378 4.541a1 1 0 00.878.91l.118.007h.16a1 1 0 00.98-.8l.016-.117.379-4.541a.5.5 0 00-.498-.542z"
    />
  </svg>
);
