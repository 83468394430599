import * as React from 'react';

import { Box } from '~shared/components/Box';

import {
  IconChevronProps,
  directionClassMap,
} from '../../standard/IconChevron/IconChevron';
import { useIcon } from '../../useIcon';
import { IconChevronSvg } from './IconChevronSvg';

export function IconChevron({
  direction = 'down',
  className,
  ...props
}: IconChevronProps) {
  const iconProps = useIcon({ size: 'micro', ...props });
  const iconClass = [
    'transform origin-center transition-transform duration-300 ease-in-out',
    directionClassMap[direction],
    className,
  ].join(' ');

  return <Box tagName={IconChevronSvg} className={iconClass} {...iconProps} />;
}
