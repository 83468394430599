import React from 'react';
import ReactDOM from 'react-dom';

import { useToaster } from './hooks/useToaster';
import { ToastBar } from './ToastBar';

export const Toaster = () => {
  const { toasts, handlers } = useToaster();
  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 pointer-events-none z-101 pt-8 bg-transparent flex items-center flex-col justify-start"
      onMouseEnter={handlers.startPause}
      onMouseLeave={handlers.endPause}
    >
      {toasts.map((toast) => {
        return <ToastBar key={toast.id} toast={toast} />;
      })}
    </div>,
    document.querySelector('body')
  );
};
