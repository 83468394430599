import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconInformationSvg } from './IconInformationSvg';

export type IconInformationProps = UseIconProps;

export function IconInformation(props: IconInformationProps) {
  const iconProps = useIcon({ size: 'large', ...props });

  return <Box tagName={IconInformationSvg} {...iconProps} />;
}
