import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconEllipsisSvg } from './IconEllipsisSvg';

export type IconEllipsisProps = UseIconProps;

export function IconEllipsis(props: IconEllipsisProps) {
  const iconProps = useIcon({ size: 'micro', ...props });

  return <Box tagName={IconEllipsisSvg} {...iconProps} />;
}
