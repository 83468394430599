export type Tone = 'normal' | 'positive' | 'critical';
export type Theme = 'dark' | 'light';
export type Preset = 'closeable' | null;
export type Icon = string | null;

export interface Toast {
  id: string;
  message: Message;
  icon?: string | null; // '🔥' - accepts emoji
  preset?: Preset;
  disableClose?: boolean;
  duration?: number;
  theme?: Theme;
  tone?: Tone;
  createdAt: number;
  visible: boolean;
  pauseDuration: number;
}

export interface ToastOptions {
  theme?: Theme;
  duration?: number;
  tone?: Tone;
  icon?: Icon;
  preset?: Preset;
  disableClose?: boolean;
}

// if message is a non function
export type Message = MessageType<Standard, Toast>;
export type Standard = JSX.Element | string | null;
export type MessageFunction<TValue, TArg> = (arg: TArg) => TValue;
export type MessageType<TValue, TArg> = TValue | MessageFunction<TValue, TArg>;

export interface State {
  toasts: Toast[];
  pausedAt: number | undefined;
}

export enum ActionType {
  ADD_TOAST,
  DISMISS_TOAST,
  REMOVE_TOAST,
  START_PAUSE,
  END_PAUSE,
}

export type Action =
  | {
      type: ActionType.ADD_TOAST;
      toast: Toast;
    }
  | {
      type: ActionType.DISMISS_TOAST;
      toastId?: string;
    }
  | {
      type: ActionType.REMOVE_TOAST;
      toastId?: string;
    }
  | {
      type: ActionType.START_PAUSE;
      time: number;
    }
  | {
      type: ActionType.END_PAUSE;
      time: number;
    };
