import React from 'react';

import { Toast } from '../types';
import { Action, ActionType, State } from '../types';
import { reducer } from './reducer';

export let toastStore: State = { toasts: [], pausedAt: null };
const listeners: Array<(state: State) => void> = [];

// toast store - uses listener paradigm to keep components synced to changes
export const useStore = () => {
  const [state, setState] = React.useState(toastStore);
  React.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);
  const mergedToasts = state.toasts.map((t: Toast) => ({
    ...t,
  }));

  return {
    ...state,
    toasts: mergedToasts,
  };
};

export const dispatcher = (action: Action) => {
  toastStore = reducer(toastStore, action);
  listeners.forEach((listener) => {
    listener(toastStore);
  });
};

const toastTimeouts = new Map<Toast['id'], ReturnType<typeof setTimeout>>();

export const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatcher({ type: ActionType.REMOVE_TOAST, toastId });
  }, 100);
  toastTimeouts.set(toastId, timeout);
};

export const clearFromRemoveQueue = (toastId: string) => {
  const timeout = toastTimeouts.get(toastId);
  if (timeout) {
    clearTimeout(timeout);
  }
};
