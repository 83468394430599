import { Toast } from '../types';
import { Action, ActionType, State } from '../types';
import { addToRemoveQueue } from './store';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ADD_TOAST:
      return {
        ...state,
        toasts: [action.toast, ...state.toasts],
      };
    case ActionType.DISMISS_TOAST: {
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter(
          (toast: Toast) => toast.id !== action.toastId
        ),
      };
    }
    case ActionType.REMOVE_TOAST: {
      const { toastId } = action;
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((toast: Toast) => {
          addToRemoveQueue(toast.id);
        });
      }
      return {
        ...state,
        toasts: state.toasts.map((t: Toast) =>
          t.id === toastId || toastId === undefined
            ? { ...t, visible: false }
            : t
        ),
      };
    }
    case ActionType.START_PAUSE: {
      return {
        ...state,
        pausedAt: action.time,
      };
    }
    case ActionType.END_PAUSE: {
      const diff = action.time - (state.pausedAt || 0);

      return {
        ...state,
        pausedAt: undefined,
        toasts: state.toasts.map((t) => ({
          ...t,
          pauseDuration: t.pauseDuration + diff,
        })),
      };
    }
  }
};
