import * as React from 'react';

import { SVGProps } from '../../types';

export const IconCheckmarkSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M25.792 12.294a1 1 0 011.5 1.318l-.084.094-10.968 11a1 1 0 01-1.322.084l-.094-.084-6.032-6.05a1 1 0 011.322-1.495l.094.083 5.324 5.339 10.26-10.29z" />
  </svg>
);
