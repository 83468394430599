import React from 'react';

import { toast } from '../store/actions';
import { dispatcher, useStore } from '../store/store';
import { ActionType } from '../types';

// hook to manage the display and handling of the toasts
export const useToaster = () => {
  const { toasts, pausedAt } = useStore();

  React.useEffect(() => {
    if (pausedAt) {
      return;
    }

    const now = Date.now();
    const timeouts = toasts.map((toastItem) => {
      if (toastItem.duration === Infinity) {
        return;
      }

      const durationLeft =
        (toastItem.duration || 0) +
        toastItem.pauseDuration -
        (now - toastItem.createdAt);

      if (durationLeft < 0) {
        if (toastItem.visible) {
          toast.dismiss(toastItem.id);
        }
        return;
      }
      return setTimeout(() => toast.dismiss(toastItem.id), durationLeft);
    });

    return () => {
      timeouts.forEach((timeout) => timeout && clearTimeout(timeout));
    };
  }, [toasts, pausedAt]);

  // simple handlers for pausing and resuming toast timeouts
  const handlers = React.useMemo(
    () => ({
      startPause: () => {
        dispatcher({
          type: ActionType.START_PAUSE,
          time: Date.now(),
        });
      },
      endPause: () => {
        if (pausedAt) {
          dispatcher({ type: ActionType.END_PAUSE, time: Date.now() });
        }
      },
    }),
    [pausedAt]
  );

  return {
    toasts,
    handlers,
  };
};
