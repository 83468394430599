import * as React from 'react';

import { SVGProps } from '../../types';

export const IconExclamationSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M9 12a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm.963-9a.5.5 0 01.5.5l-.001.036-.396 5.535A1 1 0 019.07 10h-.14a1 1 0 01-.997-.929l-.396-5.535A.5.5 0 018.001 3h1.962z"
    />
  </svg>
);
