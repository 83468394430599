import * as React from 'react';

import { SVGProps } from '../../types';

export const IconExclamationSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M18 21a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm.963-9a.5.5 0 01.5.5l-.001.036-.396 5.535a1 1 0 01-.997.929h-.138a1 1 0 01-.997-.929l-.396-5.535a.5.5 0 01.463-.535h.018L18.963 12z"
    />
  </svg>
);
