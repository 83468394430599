import * as React from 'react';

import { SVGProps } from '../../types';

export const IconXSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.613 9.21l.094.083L18 16.585l7.293-7.292a1 1 0 011.497 1.32l-.083.094L19.415 18l7.292 7.293a1 1 0 01-1.32 1.497l-.094-.083L18 19.415l-7.293 7.292a1 1 0 01-1.497-1.32l.083-.094L16.585 18l-7.292-7.293a1 1 0 011.32-1.497z" />
  </svg>
);
