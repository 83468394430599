import * as React from 'react';

import { SVGProps } from '../../types';

export const IconArrowSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M28.707 18.707a1 1 0 01-1.32.083l-.094-.083L19 10.415V28a1 1 0 01-1.993.117L17 28V10.415l-8.293 8.292a1 1 0 01-1.497-1.32l.083-.094 10-10a1 1 0 011.414 0l10 10a1 1 0 010 1.414z" />
  </svg>
);
