import * as React from 'react';

import { SVGProps } from '../../types';

export const IconChevronSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    aria-labelledby={titleId}
    fill="none"
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      id="Line"
      d="M23.4495 7.5L12.5 17.9758L23.5 28.5"
      strokeWidth="2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
