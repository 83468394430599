import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconExclamationSvg } from './IconExclamationSvg';

export type IconExclamationProps = UseIconProps;

export function IconExclamation(props: IconExclamationProps) {
  const iconProps = useIcon({ size: 'micro', ...props });

  return <Box tagName={IconExclamationSvg} {...iconProps} />;
}
